import React, { useState } from 'react';
import { Flex, Text, Input, Button, Link, Stack, Img, useToast } from '@chakra-ui/react';
import logo from '../../assets/images/logowhite.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { forgotPasswordService } from '../../services/Service';
const ResetPassword = () => {
  const location = useLocation();
  const nav = useNavigate();
  const toast = useToast()
  const [loading, setloading] = useState(false);

  const handleSubmit =async event => {
    event.preventDefault();
    setloading(true)
      // Handle successful form submission
      const res = await forgotPasswordService(location?.state.email)
      console.log("🚀 ~ file: Login.js:78 ~ handleSubmit ~ res:", res)
      if(res.success){
        setloading(false)
        toast({
          title: res.message,
          status: 'success',
          duration: 3000,
          position:'top-right',
          isClosable: true,
        })
     }
      else {
        setloading(false)
        toast({
          title: res.message,
          status: 'error',
          duration: 3000,
          position:'top-right',
          isClosable: true,
        })
      }
 
  };
  return (
    <Stack gap={0} overflow={'hidden'} maxWidth={'100%'} alignItems={'center'}>
      <Stack
        w="100%"
        justifyContent={'center'}
        direction="row"
        bg={
          'var(--gradient, linear-gradient(15deg, #17496D 0%, #418DB8 92.71%))'
        }
        height={'7vh'}
        alignItems={'center'}
        boxShadow={'0px 8px 30px 0px rgba(13, 24, 33, 0.08)'}
      >
        <Img w={6} src={logo} />
        <Text fontSize={'lg'} color="white">
          HR Application
        </Text>
      </Stack>
      <Stack
        bg="#F8FBFF"
        h="93vh"
        direction="row"
        justifyContent={'center'}
        alignItems={'center'}
        width="100vw"
      >
        <Flex
           h='full'
           px={{base:6,sm:8,md:12,lg:24}}
           height={'100%'}
           width={{base:'100%',md:'60%',lg:'50%'}}
           textAlign={'left'}
           align="center"
        >
          <Stack width="100%" spacing="6">
            <Stack textAlign={'center'}>
              <Text fontWeight={'600'} fontSize={'xl'} color={'#0D1821'}>
                Reset Your Password
              </Text>
              <Text>
                An email with instructions sent to your email. Didn’t get
                email?{' '}
                <Link onClick={handleSubmit} fontSize={'md'} color="#3C98FD">
                  Resend
                </Link>
              </Text>
            </Stack>
            <Stack spacing={6}>
              <Button
              fontWeight={'500'}
                w="100%"
                color={'white'}
                borderRadius={'full'}
                bg="var(--gradient, linear-gradient(15deg, #17496D 0%, #418DB8 92.71%))"
                _hover={{}}
                type="submit"
                onClick={() => nav('/login')}
              >
                Back to login
              </Button>
            </Stack>
          </Stack>
        </Flex>
      </Stack>
    </Stack>
  );
};

export default ResetPassword;
