import { Navigate, Route } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  console.log(
    '🚀 ~ file: PrivateRoute.js:5 ~ PrivateRoute ~ userInfo:',
    userInfo
  );
  return userInfo ?  children  : <Navigate to="/" />;
};

export default PrivateRoute;
