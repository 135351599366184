import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Webchat from '../../components/webchat/Webchat';
import SignupPage from '../../components/Signup/SignupPage';
import Login from '../../components/login/Login';
import ForgotPasswordPage from '../../components/forgotpassword/ForgotPasswordPage';
import ResetPassword from '../../components/resetpassword/ResetPassword';
import ChangePassword from '../../components/changepassword/ChangePassword';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import ResendEmail from '../../components/verifyemail/ResendEmail';
import VerifyEmail from '../../components/verifyemail/VerifyEmail';
import LandingPage from '../../components/LandingPage';

const MainRoutes = () => {
  return (
    <Routes>
      <Route
        path="/chat"
        element={
          <PrivateRoute>
            <Webchat />
          </PrivateRoute>
        }
      />
      <Route
        path="/chat/:id"
        element={
          <PrivateRoute>
            <Webchat />
          </PrivateRoute>
        }
      />
      <Route
        path="/signup"
        element={
          <PublicRoute>
            <SignupPage />
          </PublicRoute>
        }
      />
      <Route
        path="/forgotpassword"
        element={
          <PublicRoute>
            <ForgotPasswordPage />
          </PublicRoute>
        }
      />
      <Route
        path="/reset-password"
        element={
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/resend-email"
        element={
          <PublicRoute>
            <ResendEmail />
          </PublicRoute>
        }
      />
      <Route
        path="/change-password/:token"
        element={
          <PublicRoute>
            <ChangePassword />
          </PublicRoute>
        }
      />
       <Route
        path="/verify-email/:token"
        element={
          <PublicRoute>
            <VerifyEmail />
          </PublicRoute>
        }
      />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
       <Route
        path="/"
        element={
          <PublicRoute>
            <LandingPage />
          </PublicRoute>
        }
      />
      <Route
        path="/:token"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
    </Routes>
  );
};

export default MainRoutes;
