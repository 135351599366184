import React from 'react';
import { Avatar } from '@chakra-ui/react';

const getInitials = name => {
  const nameParts = name.split(' ');

  if (nameParts.length === 1) {
    return nameParts[0].slice(0, 2).toUpperCase();
  }

  const firstNameInitial = nameParts[0].charAt(0).toUpperCase();
  const lastNameInitial = nameParts[nameParts.length - 1]
    .charAt(0)
    .toUpperCase();

  return `${firstNameInitial}${lastNameInitial}`;
};

const NameAvatar = ({ name }) => {
  const initials = getInitials(name);

  return (
    <Avatar
      size="sm"
      name={name}
      src=""
      color={'white'}
      borderColor="rgba(23, 73, 109, 1)"
      bg="rgba(23, 73, 109, 1)"
    ></Avatar>
  );
};

export default NameAvatar;
