import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Stack,
  Text,
  Button,
  Img,
  HStack,
  Heading,
  Input,
  Divider,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react';
import Sidebar from './Sidebar';
import avatar1 from '../../assets/images/avatar1.svg';
import avatar2 from '../../assets/images/avatar2.svg';
import background from '../../assets/images/Isolation_Mode.png';
import Logo from '../../assets/images/Logos.png';
import loader from '../../assets/images/loadergif.GIF';
import replay from '../../assets/images/replay.png';
import ability from '../../assets/images/ability.png';
import restrictions from '../../assets/images/restrictions.png';
import sample from '../../assets/images/sample.png';
import send from '../../assets/images/send.png';
import { MdSend } from 'react-icons/md';
import Drawer from './Drawer';
import {
  CreateChatService,
  LikeDislikeChatService,
  UpdateChatService,
  chatModalService,
  getChatTitleService,
  getSingleSessionService,
} from '../../services/Service';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PersonaChatContext from '../../context/ChatContext';
import NameAvatar from '../../utils/NameAvatar';
import {
  AiOutlineDislike,
  AiOutlineLike,
  AiFillLike,
  AiFillDislike,
} from 'react-icons/ai';
const Webchat = () => {
  const { getChatSessions } = useContext(PersonaChatContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [showChat, setshowChat] = useState(false);
  const [loading, setLoading] = useState(false);
  const faqRef = useRef(null);
  const [query, setQuery] = useState('');
  const [currentQuery, setcurrentQuery] = useState('');
  const [chatId, setChatId] = useState();
  const toast = useToast();
  const sampleQuestionArray = [
    {
      image: sample,
      title: 'Sample',
      question1: `What is minimum wage in ontario?`,
      question2: `Tell me Bill 27 EMPLOYMENT PROTECTION FOR FOREIGN NATIONALS ACT, 2009?`,
    },
    {
      // image: sample,
      // title: 'Sample',
      question1: `What is minimum wage in ontario?`,
      question2: `Tell me Bill 27 EMPLOYMENT PROTECTION FOR FOREIGN NATIONALS ACT, 2009?`,
    },
    {
      // image: restrictions,
      // title: 'Restriction',
      question1: `What is minimum wage in ontario`,
      question2: `Tell me Bill 27 EMPLOYMENT PROTECTION FOR FOREIGN NATIONALS ACT, 2009?`,
    },
  ];


  const [faqs, setFaqs] = useState([]); // Array of objects containing queries and answers
  console.log('🚀 ~ file: Webchat.js:71 ~ Webchat ~ faqs:', faqs);
  useEffect(() => {
    ScrollToBottom();
  }, [faqs]);
  
  const ScrollToBottom = () => {
    if (faqRef.current) {
      faqRef.current.scrollTop = faqRef.current.scrollHeight;
    }
  };
  // Create Chat
  const createChat = async ques => {
    const currentques = ques || query;

    console.log('🚀 ~ file: Webchat.js:75 ~ createChat ~ ques:', ques);
    setLoading(true);
    if (ques) {
      setshowChat(true);
    }
    // Add the new query to the array of FAQs
    setFaqs(prevFaqs => [
      ...prevFaqs,
      {
        query: query || ques,
        answer: '',
        Reference_Link: '',
        reference_name: '',
        is_liked: false,
        is_disliked: false,
        _id: '',
      },
    ]);
    setcurrentQuery(query);
    setQuery('');

    // Clear the current query input
    try {
      const res = await chatModalService(query || ques);
      console.log('🚀 ~ file: Webchat.js:58 ~ createChat ~ res:', res);
      if (res == 'server error') {
        setFaqs(prevFaqs => prevFaqs.slice(0, -1));
        toast({
          title: 'Service Unavailable',
          description: 'Server is under maintenance. Please Try again',
          status: 'error',
          duration: 6000,
          isClosable: true,
          position: 'top-right',
        });
        setshowChat(false);
        setQuery(currentques);
        setLoading(false);
      } else if (res.success) {
        try {
          getChatTitleService(currentques)
            .then(res2 => {
              console.log(
                '🚀 ~ file: Webchat.js:123 ~ getChatTitleService ~ res:',
                res2
              );
              if (res2.success) {
                CreateChatService(
                  query || ques,
                  res.text,
                  res2?.text || query?.slice(0, 9) || ques?.slice(0, 9),
                  res.Reference_Link !== ''
                    ? res.Reference_Link
                    : 'No reference link for this query',
                  res.Reference_name !== ''
                    ? res.Reference_name
                    : 'No reference link for this query'
                )
                  .then(response => {
                    console.log(
                      '🚀 ~ file: Webchat.js:96 ~ createChat ~ response:',
                      response
                    );
                    getChatSessions().then(res => {});
                    setFaqs(prevFaqs => {
                      const updatedFaqs = [...prevFaqs];
                      updatedFaqs[updatedFaqs.length - 1].answer =
                        response.data.messages[0].reply;
                      updatedFaqs[updatedFaqs.length - 1].Reference_Link =
                        response.data.messages[0].reference_link;
                      updatedFaqs[updatedFaqs.length - 1].reference_name =
                        response.data.messages[0].reference_name;
                      updatedFaqs[updatedFaqs.length - 1]._id =
                        response.data.messages[0]._id;

                      return updatedFaqs;
                    });
                    setChatId(response.data._id);
                    setLoading(false);
                  })
                  .catch(err => {
                    setLoading(false);
                    console.log(err);
                  });
              }
            })
            .catch(err => {
              setLoading(false);
              console.log(err);
            });
        } catch (error) {}


        // Clear modal answer
      } else {
        setLoading(false);
        console.log(res.message);
      }
    } catch (error) {
      setLoading(false);
      if ((error = 'The user aborted a request.')) {
        setFaqs(prevFaqs => prevFaqs.slice(0, -1));
        setshowChat(false);
        setQuery(currentQuery);
        console.log(
          '🚀 ~ file: Webchat.js:259 ~ updateChat ~ currentQuery:',
          currentQuery
        );
        toast({
          title: 'Request Timed out!',
          description: 'Your request got timed out. Please Try again later',
          status: 'error',
          duration: 6000,
          isClosable: true,
          position: 'top-right',
        });
      }
      console.log('🚀 ~ file: Webchat.js:135 ~ createChat ~ error:', error);
    }
  };
  const updateChat = async () => {

    setLoading(true);
    // Add the new query to the array of FAQs
    setFaqs(prevFaqs => [
      ...prevFaqs,
      { query: query, answer: '', Reference_Link: '' },
    ]);
    const currentques = query;
    setcurrentQuery(query);
    setQuery('');
    // Clear the current query input
    try {
      const res = await chatModalService(query);
      console.log('🚀 ~ file: Webchat.js:58 ~ createChat ~ res:', res);
      if (res == 'server error') {
        setFaqs(prevFaqs => prevFaqs.slice(0, -1));
        toast({
          title: 'Service Unavailable',
          description: 'Server is under maintenance. Please Try again',
          status: 'info',
          duration: 6000,
          isClosable: true,
          position: 'top-right',
        });
        setQuery(currentques);

        setLoading(false);
      } else if (res.success) {
        UpdateChatService(
          chatId,
          query,
          res.text,
          res.Reference_Link !== ''
            ? res.Reference_Link
            : 'No reference link for this query',
          res.Reference_name !== ''
            ? res.Reference_name
            : 'No reference link for this query'
        )
          .then(response => {
            setFaqs(prevFaqs => {
              const updatedFaqs = [...prevFaqs];
              updatedFaqs[updatedFaqs.length - 1].answer =
                response.data.messages[response.data.messages.length - 1].reply;
              updatedFaqs[updatedFaqs.length - 1].Reference_Link =
                response.data.messages[
                  response.data.messages.length - 1
                ].reference_link;
              updatedFaqs[updatedFaqs.length - 1].reference_name =
                response.data.messages[
                  response.data.messages.length - 1
                ].reference_name;
              updatedFaqs[updatedFaqs.length - 1]._id =
                response.data.messages[response.data.messages.length - 1]._id;
              return updatedFaqs;
            });
            setChatId(res.data._id);
            setLoading(false);
          })
          .catch(err => {
            setLoading(false);
            console.log(err);
          });


        // Clear modal answer
      } else {
        setLoading(false);
        console.log(res.message);
      }
    } catch (error) {
      setLoading(false);
      if ((error = 'The user aborted a request.')) {
        setFaqs(prevFaqs => prevFaqs.slice(0, -1));
        setQuery(currentQuery);

        toast({
          title: 'Request Timed out!',
          description: 'Your request got timed out. Please Try again later',
          status: 'info',
          duration: 6000,
          isClosable: true,
          position: 'top-right',
        });
      }
      console.log('🚀 ~ file: Webchat.js:156 ~ updateChat ~ error:', error);
    }
  };
  const likeDislikeHandler = async (id, liked) => {
    try {
      const res = await LikeDislikeChatService(
        chatId,
        id,
        liked ? true : false,
        liked ? false : true
      );
      console.log('🚀 ~ file: Webchat.js:306 ~ likeDislikeHandler ~ res:', res);
      if (res.success) {
        // Find the index of the FAQ item in the array
        const faqIndex = faqs.findIndex(faq => faq._id === id);

        if (faqIndex !== -1) {
          // Create a copy of the faqs array to avoid mutating state directly
          const updatedFaqs = [...faqs];

          // Update the is_liked or is_disliked value for the specific FAQ item
          if (liked) {
            updatedFaqs[faqIndex].is_liked =
              res?.data?.is_liked === true ? true : false;
            updatedFaqs[faqIndex].is_disliked = false;
          } else {
            updatedFaqs[faqIndex].is_disliked =
              res?.data?.is_liked === true ? false : true;
            updatedFaqs[faqIndex].is_liked = false;
          }

          // Update the state with the new array
          setFaqs(updatedFaqs);
        }
      }
    } catch (error) {}
  };
  const regenerateAnswer = async () => {
    const lastFaqIndex = faqs.length - 1;
    const lastFaq = faqs[lastFaqIndex];

    // Update the last FAQ's answer to "Loading..." while waiting for the API response
    setFaqs(prevFaqs => {
      const updatedFaqs = [...prevFaqs];
      updatedFaqs[lastFaqIndex].answer = 'Awaiting answer...';
      return updatedFaqs;
    });

    // Call the API to generate a new answer
    const newAnswer = await chatModalService(lastFaq.query);

    if (newAnswer.success) {
      // Update the FAQ array with the new answer
      setFaqs(prevFaqs => {
        const updatedFaqs = [...prevFaqs];
        updatedFaqs[updatedFaqs.length - 1].answer = newAnswer.text;
        return updatedFaqs;
      });
      ScrollToBottom();
    }
  };


  useEffect(() => {
    if (id) {
      setshowChat(true);
      getSingleSessionService(id).then(res => {
        if (res.success) {
          const newChat = res?.data?.messages.map(itm => {});
          setFaqs(res.data.messages);
          setChatId(res.data._id);
        }
      });
    }
  }, [id]);

  const openChat = () => {
    setshowChat(false);
    setFaqs([]);
    setQuery('');
    setChatId();
    navigate('/chat');
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter' && loading == false) {
      console.log(
        '🚀 ~ file: Webchat.js:262 ~ handleKeyPress ~ loading:',
        loading
      );
      if (faqs.length > 0) {
        updateChat();
      } else {
        setshowChat(true);
        createChat();
      }
    }
  };
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  return (
    <Stack
      fontFamily={'Poppins !important'}
      direction={{ base: 'column', md: 'row' }}
      width={'100%'}
    >
      .htaccess
      <Sidebar openChat={openChat} />
      <Drawer openChat={openChat} />
      <Stack
        // justifyContent={'space-between'}
        spacing={16}
        width={{ base: '100%', md: '60%', lg: '70%', xl: '75%' }}
        px={2}
      >
        <Stack>
          {!showChat ? (
            <Stack
              py={8}
              minH={'100vh'}
              justifyContent={'space-between'}
              w="100%"
              alignItems={'center'}
            >
              <HStack alignSelf={{ base: 'center', md: 'start' }} spacing={6}>
                <Img w={10} src={Logo} />
                <Heading
                  color={'#1B2559'}
                  fontWeight={'700'}
                  fontSize={{ base: '20px', lg: '28px' }}
                  fontFamily={'Poppins !important'}
                >
                  HR Application Chat
                </Heading>
              </HStack>
              <Stack alignItems={'center'}>
                <Stack w={'90%'}>
                  <Stack pt={4} alignItems={'center'}>
                    <Img w={6} src={sample} />
                    <Heading fontFamily={'Poppins'} fontSize="md">
                      Sample Questions
                    </Heading>
                  </Stack>
                  <SimpleGrid pt={8} spacing={6} columns={[1, 1, 2, 3, 3]}>
                    {sampleQuestionArray.map(obj => (
                      <Stack spacing={5} alignItems={'center'}>
                        <Stack
                          justifyContent={'center'}
                          fontSize={'xs'}
                          onClick={async () => {
                            await createChat(obj.question1);
                          }}
                          textAlign={'center'}
                          py={4}
                          px={2}
                          height={'70px'}
                          w="100%"
                          borderRadius={'2xl'}
                          border="1px solid #418DB8"
                          _hover={{
                            cursor: 'pointer',
                            bgColor: '#418DB8',
                            color: 'white',
                          }}
                          boxShadow={
                            '0px 0px 40px 0px rgba(145, 158, 171, 0.35);'
                          }
                          bg="white"
                          color="#637381"
                        >
                          <Text>{obj.question1}</Text>
                        </Stack>
                        <Stack
                          justifyContent={'center'}
                          fontSize={'xs'}
                          onClick={async () => {
                            await createChat(obj.question2);
                            // await new Promise(resolve => {
                            //   setQuery(obj.question2, () => {
                            //     resolve();
                            //   });
                            // });
                          }}
                          textAlign={'center'}
                          py={4}
                          px={2}
                          height={'70px'}
                          w="100%"
                          borderRadius={'2xl'}
                          border="1px solid #418DB8"
                          _hover={{
                            cursor: 'pointer',
                            bgColor: '#418DB8',
                            color: 'white',
                          }}
                          boxShadow={
                            '0px 0px 40px 0px rgba(145, 158, 171, 0.35);'
                          }
                          bg="white"
                          color="#637381"
                        >
                          <Text>{obj.question2}</Text>
                        </Stack>
                      </Stack>
                    ))}
                  </SimpleGrid>
                </Stack>
              </Stack>
              <Stack alignItems={'center'} w={'100%'}>
                <InputGroup
                  mt={{ base: 12 }}
                  border="1px solid #418DB8"
                  borderRadius={'lg'}
                  size="lg"
                  fontSize={'md'}
                  boxShadow={'0px 0px 40px 0px rgba(145, 158, 171, 0.35);'}
                  w="90%"
                >
                  <Input
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    onKeyDown={handleKeyPress}
                  />
                  <InputRightElement>
                    <MdSend
                      cursor={query.length > 0 ? 'pointer' : ''}
                      onClick={() => {
                        if (query && query.trim().length !== 0) {
                          setshowChat(true);
                          createChat();
                        }
                      }}
                      color="green.500"
                    />
                  </InputRightElement>
                </InputGroup>
                <Text
                  w="90%"
                  textAlign={'center'}
                  px={4}
                  color={'#637381'}
                  fontSize={{ base: 'xs', sm: 'sm' }}
                >
                  Our HR Application in its first release and beta version.
                  Please bear with us as we continue to improve your experience.
                </Text>
              </Stack>
            </Stack>
          ) : (
            <>
              <Stack minH={'80vh'} py={8} justifyContent={'space-between'}>
                <Stack>
                  <HStack
                    alignSelf={{ base: 'center', md: 'start' }}
                    spacing={6}
                  >
                    <Img w={8} src={Logo} />
                    <Heading
                      color={'#1B2559'}
                      fontWeight={'700'}
                      fontSize={{ base: '20px', lg: '28px' }}
                      fontFamily={'Poppins !important'}
                    >
                      HR Application Chat
                    </Heading>
                  </HStack>
                </Stack>
                <Stack
                  ref={faqRef}
                  pt={2}
                  maxH={'60vh'}
                  h={'60vh'}
                  id="chat-container"
                  overflowY={'auto'}
                >
                  {console.log('faqs:::>', faqs)}
                  {faqs.map((faq, index) => (
                    <Stack key={index} mb={4} w="100%" alignItems={'center'}>
                      <HStack
                        w="100%"
                        p={2}
                        justifyContent={{ base: 'normal', lg: 'center' }}
                        alignItems={'start'}
                      >
                        {/* <Img src={avatar1} /> */}
                        <NameAvatar name={userInfo?.name} />
                        <Stack
                          border={'1px solid #E2E8F0'}
                          borderRadius={'10px'}
                          p={3}
                          w={'80%'}
                        >
                          <Text
                            color={'#1C1C1C'}
                            fontSize={'15px'}
                            fontWeight={'600'}
                            lineHeight={1.7}
                          >
                            {faq?.query || faq?.text}
                          </Text>
                        </Stack>
                      </HStack>
                      <HStack
                        w="100%"
                        p={2}
                        justifyContent={{ base: 'normal', lg: 'center' }}
                      >
                        <Img w={8} src={avatar2} alignSelf={'baseline'} />
                        <Stack
                          borderRadius={'10px'}
                          bgColor={'#FFFFF'}
                          boxShadow={'lg'}
                          p={3}
                          w={'80%'}
                          gap={3}
                          // alignItems={'center'}
                        >
                          {faq?.answer == '' ? (
                            <Img alignSelf={'center'} w={14} src={loader} />
                          ) : (
                            <HStack
                              justifyContent={'space-between'}
                              alignItems={'baseline'}
                              spacing={4}
                            >
                              <Text
                                color={'#1B2559'}
                                fontSize={'14px'}
                                fontWeight={'400'}
                                w="90%"
                              >
                                {faq?.answer ||
                                  faq?.reply ||
                                  'Awaiting answer...'}
                              </Text>

                                <HStack alignItems={'center'} spacing={1}>
                                  {faq?.is_liked == true ? (
                                    <AiFillLike
                                      color="#1B2559"
                                      fontSize={'1.5rem'}
                                    />
                                  ) : (
                                    <AiOutlineLike
                                      onClick={() =>
                                        likeDislikeHandler(faq?._id, true)
                                      }
                                      cursor="pointer"
                                      color="#1B2559"
                                      fontSize={'1.5rem'}
                                    />
                                  )}
                                  {faq?.is_disliked == true ? (
                                    <AiFillDislike
                                      color="#1B2559"
                                      fontSize={'1.5rem'}
                                    />
                                  ) : (
                                    <AiOutlineDislike
                                      onClick={() =>
                                        likeDislikeHandler(faq?._id, false)
                                      }
                                      cursor="pointer"
                                      color="#1B2559"
                                      fontSize={'1.5rem'}
                                    />
                                  )}
                                </HStack>
                            </HStack>
                          )}

                          <Text
                            fontStyle={'italic'}
                            textAlign={'start'}
                            color={'#418DB8'}
                            fontSize={'14px'}
                            fontWeight={'600'}
                          >
                            {/* Reference :{' '} */}
                            {faq?.reference_name ==
                            'No reference link for this query' ? (
                              faq?.reference_name
                            ) : (
                              <a
                                target="#"
                                href={
                                  faq?.Reference_Link || faq?.reference_link
                                }
                                color="#1B2559"
                              >
                                {faq?.reference_name}
                              </a>
                            )}
                          </Text>
                        </Stack>
                      </HStack>
                      {/* {index === faqs.length - 1 && (
                        <HStack
                          cursor={'pointer'}
                          // onClick={regenerateAnswer}
                          color={'#1B2559'}
                        >
                          <Img src={replay} />
                          <Text
                            textAlign={'center'}
                            fontWeight={'500'}
                            fontSize={'14px'}
                          >
                            Regenerate response
                          </Text>
                        </HStack>
                      )} */}
                    </Stack>
                  ))}
                </Stack>
              </Stack>
              <Stack>
                <HStack mt={4} justifyContent={'center'}>
                  <Stack width={{ base: '70%', md: '75%' }}>
                    <Input
                      className="input-field"
                      placeholder="Write a message"
                      type="text"
                      value={query}
                      onChange={e => setQuery(e.target.value)}
                      border={'1px solid rgba(226, 232, 240, 1)'}
                      borderRadius={'25px'}
                      fontSize={'14px'}
                      isDisabled={loading}
                      onKeyPress={event => {
                        if (event.key === 'Enter') {
                          updateChat();
                        }
                      }}
                    />
                  </Stack>
                  <Button
                    width={{ base: '30%', md: '20%', lg: '15%' }}
                    borderRadius={'45px'}
                    color={'white'}
                    fontSize={'14px'}
                    fontWeight={'600'}
                    bgGradient="linear-gradient(15deg, #17496D 0%, #418DB8 92.71%)"
                    _hover={{}}
                    // boxShadow={'0px 21px 27px -10px rgba(96, 60, 255, 0.48)'}
                    onClick={updateChat}
                    isDisabled={loading || !query || query.trim().length == 0}
                    onKeyDown={handleKeyPress}
                  >
                    Submit
                  </Button>
                </HStack>
                <Text
                  color={'#718096'}
                  fontSize={'xs'}
                  fontWeight={'500'}
                  align={'center'}
                >
                  Free Research Preview. HR Application may produce inaccurate
                  information about people, places, or facts.
                </Text>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Webchat;
