import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Image,
  Heading,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Link,
  Stack,
  Img,
  Checkbox,
  useToast,
  getToken,
} from '@chakra-ui/react';
import { EmailIcon, LockIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import loginImage from '../../assets/images/login.png';
import logo from '../../assets/images/logowhite.png';
import './Login.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { loginUserService, verifyEmailService } from '../../services/Service';
import axios from 'axios';
import { baseuRL } from '../../environment';
const Login = () => {
  const toast = useToast();
  const nav = useNavigate();
  const location = useLocation()
  const { token } = useParams();
  console.log('🚀 ~ file: Login.js:29 ~ Login ~ token:', token);
  const [showPassword, setShowPassword] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loading, setloading] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [submitClicked, setSubmitClicked] = useState(false);
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = event => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };
  const handlePasswordChange = event => {
    const newPassword = event.target.value;
    setPassword(newPassword);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setSubmitClicked(true);

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s).{8,}$/;

    const isValidEmail = emailRegex.test(email);
    const isValidPassword = passwordRegex.test(password);

    if (!isValidEmail) {
      setEmailError('Invalid email address.');
    } else {
      setEmailError('');
    }

    if (!isValidPassword) {
      setPasswordError(
        'Password must contain at least 8 characters, one uppercase, one lowercase, and one digit.'
      );
    } else {
      setPasswordError('');
    }

    if (isValidEmail && isValidPassword) {
      setloading(true);
      // Handle successful form submission
      console.log('Form submitted successfully.');
      const res = await loginUserService(email, password);
      console.log('🚀 ~ file: Login.js:78 ~ handleSubmit ~ res:', res);
      if (res.success) {
        localStorage.setItem('userInfo', JSON.stringify(res?.user));
        setloading(false);
        toast({
          title: 'Logged in successfully',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        nav('/chat');
        setEmail('');
        setPassword('');
      } else {
        setloading(false);
        toast({
          title: res.message,
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } else {
      console.log('Form has errors. Please correct them.');
    }
  };

  useEffect(() => {
  if(location.state === 'verify-email'){
    toast({
      title:"Success! Your Email Address is Verified",
      status: 'success',
      duration: 3000,
      position: 'top-right',
      isClosable: true,
    });
  }
  }, [location])
  

  const isSubmitDisabled = !email || !password;
  // useEffect(() => {
  //   if (token) {
  //     const verifyHandler = async () => {
  //       try {
  //         const res = await verifyEmailService(token);
  //         console.log('🚀 ~ file: Login.js:115 ~ verifyHandler ~ res:', res);
  //         if (res.success) {
  //           nav('/');
  //           toast({
  //             title: res.message,
  //             status: 'success',
  //             duration: 3000,
  //             position: 'top-right',
  //             isClosable: true,
  //           });
  //         } else {
  //           toast({
  //             title: res.message,
  //             status: 'error',
  //             duration: 3000,
  //             position: 'top-right',
  //             isClosable: true,
  //           });
  //         }
  //       } catch (error) {
  //         console.log(
  //           '🚀 ~ file: Login.js:137 ~ verifyHandler ~ error:',
  //           error
  //         );
  //       }
  //     };
  //     verifyHandler();
  //   }
  // }, [token]);

  useEffect(() => {
    if (token) {
      let data = JSON.stringify({
        verificationToken: token,
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: baseuRL + "/api/verify-email",
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios
        .request(config)
        .then(response => {
          console.log('🚀 ~ file: Login.js:73 ~ .then ~ response:', response);
          console.log(JSON.stringify(response.data));
          if (response?.data.success) {
            // setLoader(false);
            // localStorage.setItem('userInfo', response?.data.user);

            nav('/login', {state: 'verify-email'});

            // Toaster(toast, 'Success', "Your email has been verified", 'success');
           
          } else {
            // setLoader(false);
            // Toaster(toast, 'Error', response?.data?.message, 'error');
            // toast({
            //   title: response.data.message,
            //   status: 'error',
            //   duration: 3000,
            //   position: 'top-right',
            //   isClosable: true,
            // });

            // nav('/');
          }
        })
        .catch(error => {
          // Toaster(toast, 'Error', error, 'error');

          toast({
            title: error.data.message,
            status: 'error',
            duration: 3000,
            position: 'top-right',
            isClosable: true,
          });
        });
    }
  }, [token]);

  return (
    <Stack gap={0} overflow={'hidden'} maxWidth={'100%'} alignItems={'center'}>
      <Stack
        w="100%"
        justifyContent={'center'}
        direction="row"
        bg={
          'var(--gradient, linear-gradient(15deg, #17496D 0%, #418DB8 92.71%))'
        }
        height={'7vh'}
        alignItems={'center'}
        boxShadow={'0px 8px 30px 0px rgba(13, 24, 33, 0.08)'}
      >
        <Img w={6} src={logo} />
        <Text fontSize={'lg'} color="white">
          HR Application
        </Text>
      </Stack>

      <Stack
        className="signup-container"
        direction={{ base: 'column', md: 'row' }} // Stack direction changes based on screen size
        // justifyContent="center"
        // alignItems="center"
        width="100vw"
      >
        {/* Image Section */}
        <Flex
          display={{ base: 'none', md: 'flex' }} // Hide on mobile
          width="50%"
          justifyContent="center"
          alignItems="center"
          maxH="100vh"
        >
          <Image
            src={loginImage}
            alt="Signup Image"
            objectFit="cover"
            width="60%"
          />
        </Flex>

        {/* Form Section */}
        <Flex
          px={{ base: 6, sm: 8, md: 12, lg: 24 }}
          py={4}
          h={'full'}
          bg={{ base: 'transparent', md: '#F8FBFF' }}
          width={{ base: '100%', md: '50%' }}
          textAlign="left"
          align="center"
        >
          <Stack width="100%" spacing={6}>
            <Stack>
              <Text fontWeight="600" fontSize="xl" color="#196EC9">
                Login
              </Text>
              <Text>Welcome back! Glad you’re here again</Text>
            </Stack>
            <form style={{ width: '100%' }} onSubmit={handleSubmit}>
              <Stack spacing={6}>
                {/* Input Fields */}
                <Stack spacing={2}>
                  <Text fontWeight="600" fontSize="sm">
                    Email Address
                  </Text>
                  <Input
                    borderRadius="full"
                    bg="white"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  {emailError && (
                    <Text color="red" fontSize="sm">
                      {emailError}
                    </Text>
                  )}
                </Stack>
                <Stack spacing={2}>
                  <Text fontWeight="600" fontSize="sm">
                    Password
                  </Text>
                  <InputGroup>
                    <Input
                      borderRadius="full"
                      bg="white"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <InputRightElement cursor="pointer">
                      {showPassword ? (
                        <ViewOffIcon
                          color="gray.300"
                          onClick={handlePasswordVisibility}
                        />
                      ) : (
                        <ViewIcon
                          color="gray.300"
                          onClick={handlePasswordVisibility}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  {passwordError && (
                    <Text color="red" fontSize="sm">
                      {passwordError}
                    </Text>
                  )}
                </Stack>
                {/* Other Form Elements */}
                <Checkbox size="sm">Keep me logged in</Checkbox>
                <Button
                  isLoading={loading}
                  fontWeight="500"
                  color="white"
                  borderRadius="full"
                  bg="var(--gradient, linear-gradient(15deg, #17496D 0%, #418DB8 92.71%))"
                  _hover={{}}
                  type="submit"
                  isDisabled={isSubmitDisabled}
                >
                  Login
                </Button>
              </Stack>
            </form>
            <Link
              onClick={() => nav('/forgotpassword')}
              textAlign="center"
              fontSize="md"
              color="#3C98FD"
            >
              Forgot Your Password?
            </Link>
            <Text textAlign="center">
              Don’t have an account?{' '}
              <Link onClick={() => nav('/signup')} color="#3C98FD">
                Signup
              </Link>
            </Text>
          </Stack>
        </Flex>
      </Stack>
    </Stack>
  );
};

export default Login;
