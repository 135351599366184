import React, { useEffect, useState } from 'react';
import {
  Flex,
  Text,
  Input,
  Button,
  Link,
  Stack,
  Img,
  useToast,
  Image,
} from '@chakra-ui/react';
import logo from '../../assets/images/logowhite.png';
import verifyEmail from '../../assets/images/verifyemail.png';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { baseuRL } from '../../environment';
const VerifyEmail = () => {
  const { token } = useParams();
  console.log('🚀 ~ file: Login.js:29 ~ Login ~ token:', token);
  const nav = useNavigate();
  const toast = useToast();
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (token) {
      setloading(true);
      let data = JSON.stringify({
        verificationToken: token,
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: baseuRL + '/api/verify-email',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios
        .request(config)
        .then(response => {
          console.log('🚀 ~ file: Login.js:73 ~ .then ~ response:', response);
          console.log(JSON.stringify(response.data));
          if (response?.data.success) {
            // setLoader(false);
            setloading(false);
            // localStorage.setItem('userInfo', response?.data.user);

            // nav('/');

            // Toaster(toast, 'Success', "Your email has been verified", 'success');
          } else {
            setloading(false);
            // setLoader(false);
            // Toaster(toast, 'Error', response?.data?.message, 'error');
            toast({
              title: response.data.message,
              status: 'error',
              duration: 3000,
              position: 'top-right',
              isClosable: true,
            });
            // nav('/');
          }
        })
        .catch(error => {
          // Toaster(toast, 'Error', error, 'error');

          toast({
            title: error.data.message,
            status: 'error',
            duration: 3000,
            position: 'top-right',
            isClosable: true,
          });
        });
    }
  }, [token]);

  return (
    <Stack gap={0} overflow={'hidden'} maxWidth={'100%'} alignItems={'center'}>
      <Stack
        w="100%"
        justifyContent={'center'}
        direction="row"
        bg={
          'var(--gradient, linear-gradient(15deg, #17496D 0%, #418DB8 92.71%))'
        }
        height={'7vh'}
        alignItems={'center'}
        boxShadow={'0px 8px 30px 0px rgba(13, 24, 33, 0.08)'}
      >
        <Img w={6} src={logo} />
        <Text fontSize={'lg'} color="white">
          HR Application
        </Text>
      </Stack>
      <Stack
        bg="#F8FBFF"
        h="93vh"
        direction="row"
        justifyContent={'center'}
        alignItems={'center'}
        width="100vw"
      >
        <Flex
          h="full"
          px={{ base: 6, sm: 8, md: 12, lg: 24 }}
          height={'100%'}
          width={{ base: '100%', md: '60%', lg: '50%' }}
          textAlign={'left'}
          align="center"
        >
          <Stack alignItems={'center'} width="100%" spacing="6">
            <Image w={150} h={150} src={verifyEmail} />
            <Stack textAlign={'center'}>
              <Text fontWeight={'600'} fontSize={'xl'} color={'#0D1821'}>
                Email {loading ? 'Verifying' : 'Verified'}
              </Text>
              {!loading && <Text>Your Email is verified Successfully.</Text>}
            </Stack>
            <Stack alignItems={'center'} spacing={6}>
              <Button
                fontWeight={'500'}
                w="100%"
                color={'white'}
                borderRadius={'full'}
                bg="var(--gradient, linear-gradient(15deg, #17496D 0%, #418DB8 92.71%))"
                _hover={{}}
                type="submit"
                onClick={() => nav('/login')}
                isLoading={loading}
              >
                Back To Login
              </Button>
            </Stack>
          </Stack>
        </Flex>
      </Stack>
    </Stack>
  );
};

export default VerifyEmail;
