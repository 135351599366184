import { useToast } from '@chakra-ui/react';
import { baseuRL, localuRL } from '../environment';
const toast = useToast;
// auth module API's
export function signUpUserService(
  firstName,
  lastName,
  phoneNumber,
  state,
  country,
  email,
  password
) {
  return new Promise((resolve, reject) => {
    fetch(baseuRL + '/api/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName,
        lastName,
        phoneNumber,
        state,
        country,
        email,
        password,
        // isGoogle: false,
      }),
    })
      .then(response => {
        if (response.status === 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function loginUserService(email, password) {
  return new Promise((resolve, reject) => {
    fetch(baseuRL + '/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        // isGoogle:false,
      }),
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function forgotPasswordService(email) {
  return new Promise((resolve, reject) => {
    fetch(baseuRL + '/api/forgot-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function resetPasswordService(password, token) {
  return new Promise((resolve, reject) => {
    fetch(baseuRL + '/api/reset-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password,
        token,
      }),
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
export function verifyEmailService(token) {
  return new Promise((resolve, reject) => {
    fetch(baseuRL + '/api/verify-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ verificationToken: token }),
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
export function resendEmailService(email) {
  return new Promise((resolve, reject) => {
    fetch(baseuRL + '/api/resend-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email}),
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
// chat model API
export function chatModalService(text) {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 30000);
  return new Promise((resolve, reject) => {
    fetch(
      `https://vou5bxl8re.execute-api.us-west-2.amazonaws.com/query/Act?text=${text}`, // Fix the missing '=' here
      {
        method: 'GET',
        signal: controller.signal,
        redirect: 'follow',
      }
    )
      .then(response => {
        if (response.status === 401) {
          window.location = '/';
          localStorage.clear();
        } else if (response.status === 503) {
          // Handle the 503 error gracefully without showing the error message
          // You can choose to resolve with a predefined value or null
          resolve(null); // Or resolve with a default response
        } else if (response.status >= 500 && response.status < 550) {
          resolve('server error');
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        console.log('Error:', error);
        // Reject the promise to indicate that an error occurred
        reject(error);
      });
  });
  clearTimeout(tid);
}

export function getChatTitleService(text) {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  const controller = new AbortController();
  const tid = setTimeout(() => controller.abort(), 30000);
  return new Promise((resolve, reject) => {
    fetch(
      `https://wcmgul7q56.execute-api.us-west-2.amazonaws.com/chat_title?text=${text}`, // Fix the missing '=' here
      {
        method: 'GET',
        signal: controller.signal,
        redirect: 'follow',
      }
    )
      .then(response => {
        if (response.status === 401) {
          window.location = '/';
          localStorage.clear();
        } else if (response.status === 503) {
          // Handle the 503 error gracefully without showing the error message
          // You can choose to resolve with a predefined value or null
          resolve(null); // Or resolve with a default response
        } else if (response.status >= 500 && response.status < 550) {
          resolve('server error');
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        console.log('Error:', error);
        // Reject the promise to indicate that an error occurred
        reject(error);
      });
  });
  clearTimeout(tid);
}

////----------------------------- cHAT SAVE APIS---------------------------------------////
export function CreateChatService(
  text,
  reply,
  title,
  reference_link,
  reference_name
) {
  const { token = '', _id = '' } = JSON.parse(localStorage.getItem('userInfo'));
  return new Promise((resolve, reject) => {
    fetch(baseuRL + '/chat/createChat', {
      method: 'POST',
      headers: {
        auth_token: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: _id,
        messages: [{ text, reply, reference_link, reference_name }],
        title,
      }),
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

// Update chat API
export function UpdateChatService(
  chatId,
  message,
  reply,
  reference_link,
  reference_name
) {
  const { token = '', _id = '' } = JSON.parse(localStorage.getItem('userInfo'));
  return new Promise((resolve, reject) => {
    fetch(baseuRL + '/chat/updateChat', {
      method: 'PUT',
      headers: {
        auth_token: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        chatId,
        message,
        reply,
        reference_link,
        reference_name,
      }),
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
export function LikeDislikeChatService(
  sessionId,
  chatId,
  is_liked,
  is_disliked
) {
  const { token = '', _id = '' } = JSON.parse(localStorage.getItem('userInfo'));
  return new Promise((resolve, reject) => {
    fetch(baseuRL + '/chat/like-dislike-chat', {
      method: 'POST',
      headers: {
        auth_token: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sessionId,
        chatId,
        is_liked,
        is_disliked,
      }),
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

// get all chat sessions API
export function getAllChatSessionsService() {
  const { token = '', _id = '' } = JSON.parse(localStorage.getItem('userInfo'));
  return new Promise((resolve, reject) => {
    fetch(baseuRL + `/chat/getAllChats/${_id}`, {
      method: 'GET',
      headers: {
        auth_token: token,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getSingleSessionService(id) {
  const { token = '', _id = '' } = JSON.parse(localStorage.getItem('userInfo'));
  return new Promise((resolve, reject) => {
    fetch(baseuRL + `/chat/getChat/${id}`, {
      method: 'GET',
      headers: {
        auth_token: token,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status == 401) {
          window.location = '/';
          localStorage.clear();
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

// delete single chat session API
export function deleteChatSessionsService(chatId) {
  const { token = '', _id = '' } = JSON.parse(localStorage.getItem('userInfo'));

  return new Promise((resolve, reject) => {
    fetch(baseuRL + `/chat/deleteChat/${chatId}`, {
      method: 'DELETE',
      headers: {
        auth_token: token,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status == 401) {
          // window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function deleteAllSessionsService() {
  const { token = '', _id = '' } = JSON.parse(localStorage.getItem('userInfo'));

  return new Promise((resolve, reject) => {
    fetch(baseuRL + `/chat//deleteAllChat/${_id}`, {
      method: 'DELETE',
      headers: {
        auth_token: token,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status == 401) {
          // window.location = "/";
        } else {
          resolve(response.json());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
