import React, { createContext, useEffect, useState } from "react";
import { deleteAllSessionsService, deleteChatSessionsService, getAllChatSessionsService } from "../services/Service";


const PersonaChatContext = createContext();
export function ChatContext({ children }) {
  const [sessions, setSessions] = useState();
  console.log("🚀 ~ file: ChatContext.js:11 ~ ChatContext ~ sessions:", sessions)
  
// get all chat sessions function
  const getChatSessions = async () => {
    const response = await getAllChatSessionsService();
    if (response.success) {
      setSessions(response.data);
    }

    return response;
  };
// delete single chat session from chat list
  const deleteSession = async (chatId) => {
    const response = await deleteChatSessionsService(chatId);
    if (response.success) {
      const updatedItems = sessions.filter((chat) => chat._id !== chatId);
      setSessions(updatedItems);
    }

    return response;
  };

  const deleteAllSession = async () => {
    const response = await deleteAllSessionsService();
    if (response.success) {
      setSessions([]);
    }

    return response;
  };

//   const updateChatTitle =  async (chatId,title) => {
//     const res = await UpdateChatNameService(chatId, title);
//     if (res.success) {
//       const updatedItems = sessions.map((item) => {
//         if (item._id === chatId) {
//           return { ...item, title: title };
//         }
//         return item;
//       });
  
//       setSessions(updatedItems);
//     }

//     return res;
//   }
  return (
    <PersonaChatContext.Provider
      value={{
        sessions,
        getChatSessions,
        deleteSession,
        setSessions,
        deleteAllSession
      }}
    >
      {children}
    </PersonaChatContext.Provider>
  );
}

export default PersonaChatContext;
