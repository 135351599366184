import React from 'react';
import { ChakraProvider, theme } from '@chakra-ui/react';
import MainRoutes from './utils/routes/MainRoutes';
import './App.css';
import { ChatContext } from './context/ChatContext';
function App() {
  return (
    <ChakraProvider theme={theme}>
      <ChatContext>
      <MainRoutes />
      </ChatContext>
    </ChakraProvider>
  );
}

export default App;
