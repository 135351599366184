import React, { useContext, useEffect, useState } from 'react';
import {
  Stack,
  Text,
  Button,
  Img,
  HStack,
  Heading,
  Input,
  Divider,
  Switch,
} from '@chakra-ui/react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { BsList, BsPlusCircleFill, BsPlus } from 'react-icons/bs';
import Logo from '../../assets/images/Logos.png';
import icon1 from '../../assets/images/Icon Set.png';
import icon2 from '../../assets/images/icon2.png';
import icon3 from '../../assets/images/icon3.png';
import icon4 from '../../assets/images/icon4.png';
import icon5 from '../../assets/images/Icon5.png';
import icon6 from '../../assets/images/icon6.png';
import icon7 from '../../assets/images/Icon7.png';
import avatar from '../../assets/images/Avatar Style 6.png';
import signout from '../../assets/images/Logout.png';
import PersonaChatContext from '../../context/ChatContext';
import { useNavigate } from 'react-router-dom';
import { RiDeleteBin5Line } from 'react-icons/ri';
import NameAvatar from '../../utils/NameAvatar';

const DrawerMob = ({ openChat }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const {
    setSessions,
    sessions,
    getChatSessions,
    deleteSession,
    deleteAllSession,
  } = useContext(PersonaChatContext);
  const navigator = useNavigate();
  const [showHistory, setshowHistory] = useState(true);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const fetchChatSessions = () => {
    getChatSessions().then(res => {});
  };
  useEffect(() => {
    fetchChatSessions();
  }, []); // Fetch chat sessions on component mount
  return (
    <Stack
      direction={'row'}
      width={'100%'}
      display={{ base: 'inherit', md: 'none' }}
      justifyContent={'space-between'}
      p={3}
    >
      <Stack>
        <Button
          w={'fit-content'}
          ref={btnRef}
          variant="none"
          colorScheme="teal"
          onClick={onOpen}
        >
          <BsList fontSize={'30px'} />
        </Button>
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody>
              <Stack
                bgColor={'rgba(255, 255, 255, 1)'}
                boxShadow={'2xl'}
                borderRadius={'20px'}
                marginBlock={8}
                py={4}
                h={'fit-content'}
              >
                <Stack>
                  <Stack alignItems={'center'} mb={3}>
                    <Img src={Logo} w={'52px'} />
                  </Stack>
                  <Divider mb={3} />
                  <Stack>
                    <Stack gap={4}>
                      <Stack onClick={openChat}>
                        <HStack
                          cursor={'pointer'}
                          bgColor={'rgba(247, 249, 251, 1)'}
                          borderRadius={'10px'}
                          px={4}
                          py={2}
                          mx={3}
                        >
                          <BsPlusCircleFill size={20} />
                          <Text
                            color={'#1C1C1C'}
                            fontSize={'sm'}
                            fontWeight={'400'}
                          >
                            New Chat
                          </Text>
                        </HStack>
                      </Stack>
                      {/* view/hide toggler */}
                      <HStack alignSelf={'center'} mx={3} spacing={3}>
                        <Text fontSize={'lg'} color={'#418DB8'} _hover={{}}>
                          Chat History
                        </Text>
                        <Switch
                          isChecked={showHistory}
                          onChange={() => setshowHistory(!showHistory)}
                          size={'md'}
                          id="isChecked"
                        />
                      </HStack>
                      {/* chat history */}
                      {showHistory && (
                        <Stack>
                          <Stack maxH={'50vh'} overflowY={'auto'}>
                            <Stack
                              bgColor={'rgba(247, 249, 251, 1)'}
                              borderRadius={'10px'}
                              p={4}
                              mx={3}
                            >
                              {sessions && sessions.length == 0 ? (
                                <Text textAlign={'center'}>
                                  No sessions yet
                                </Text>
                              ) : (
                                sessions?.map(session => (
                                  <Stack>
                                    <Stack
                                      onClick={() => {
                                        navigator(`/chat/${session?._id}`);
                                      }}
                                      cursor={'pointer'}
                                      alignItems={'center'}
                                      direction={'row'}
                                      justifyContent={'space-between'}
                                    >
                                      <HStack spacing={2} py={1}>
                                        <Img src={icon1} />
                                        <Text
                                          color={'rgba(28, 28, 28, 1)'}
                                          fontSize={'14px'}
                                          fontWeight={'400'}
                                        >
                                         {session?.title?.slice(0, 20)}
                                          {/* {session?.messages[0]?.text?.slice(0, 19)} */}
                                        </Text>
                                      </HStack>
                                      <RiDeleteBin5Line
                                        onClick={event => {
                                          event.stopPropagation();
                                          deleteSession(session?._id).then(
                                            res => {
                                              openChat();
                                              navigator('/chat');
                                              // }
                                            }
                                          );
                                        }}
                                        fontSize={'17px'}
                                      />
                                    </Stack>
                                    <Divider />
                                  </Stack>
                                ))
                              )}
                            </Stack>
                          </Stack>
                          <Button
                            alignSelf={'center'}
                            w="fit-content"
                            color={'white'}
                            fontSize={'12px'}
                            fontWeight={'600'}
                            bgGradient="linear-gradient(15deg, #17496D 0%, #418DB8 92.71%)"
                            _hover={{}}
                            size="sm"
                            onClick={() => {
                              deleteAllSession().then(res => {
                                openChat();
                                navigator('/chat');
                              });
                            }}
                          >
                            Clear All Conversations
                          </Button>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </Stack>

                <Stack
                  bgColor={'rgba(255, 255, 255, 1)'}
                  boxShadow={'2xl'}
                  borderRadius={'20px'}
                  direction={'row'}
                  mx={3}
                  justifyContent={'space-between'}
                  mt={4}
                >
                  <HStack marginLeft={4}>
                    {/**/}
                    <NameAvatar name={userInfo?.name} />
                    <Text
                      color={'#1B2559'}
                      fontSize={'14px'}
                      fontWeight={'700'}
                    >
                      {userInfo?.name}
                    </Text>
                  </HStack>
                  <Stack>
                    <Button
                      onClick={() => {
                        localStorage.clear();
                        navigator('/');
                      }}
                      colorScheme="teal"
                      variant="none"
                    >
                      <Img src={signout} />
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Stack>

      {/* <Text
        alignSelf={'center'}
        color={'rgba(28, 28, 28, 1)'}
        fontSize={'md'}
        fontWeight={'700'}
      >
        AI Capabilities
      </Text>
      <Stack alignSelf={'center'}>
       
        <Button colorScheme="teal" variant="none">
          <BsPlus />
        </Button>
      </Stack> */}
    </Stack>
  );
};

export default DrawerMob;
