import React, { useContext, useEffect, useState } from 'react';
import {
  Stack,
  Text,
  Button,
  Divider,
  Img,
  HStack,
  useDisclosure,
  Switch,
  Avatar,
} from '@chakra-ui/react';
import { BsList, BsPlusCircleFill } from 'react-icons/bs';
import Logo from '../../assets/images/Logos.png';
import icon1 from '../../assets/images/Icon Set.png';
import signout from '../../assets/images/Logout.png';
import { useNavigate } from 'react-router-dom';
import NameAvatar from '../../utils/NameAvatar';
import PersonaChatContext from '../../context/ChatContext';
import { RiDeleteBin5Line } from 'react-icons/ri';
const Sidebar = ({ openChat }) => {
  const {
    setSessions,
    sessions,
    getChatSessions,
    deleteSession,
    deleteAllSession,
  } = useContext(PersonaChatContext);
  const navigator = useNavigate();
  const [showHistory, setshowHistory] = useState(true);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const fetchChatSessions = () => {
    getChatSessions().then(res => {});
  };
  useEffect(() => {
    fetchChatSessions();
  }, []); // Fetch chat sessions on component mount

  return (
    <Stack
      width={{ base: '100%', md: '40%', lg: '30%', xl: '25%' }}
      display={{ base: 'none', md: 'inherit' }}
      px={5}
      pt={3}
    >
      <Stack
        bgColor={'rgba(255, 255, 255, 1)'}
        boxShadow={'2xl'}
        borderRadius={'20px'}
        justifyContent={'space-between'}
        p={5}
        // h={{ base: '100%', lg: 'fit-content' }}
        minH={'97vh'}
      >
        <Stack>
          <Stack alignItems={'center'} mb={3}>
            <Img cursor={'pointer'} src={Logo} w={'40px'} />
          </Stack>
          <Divider mb={3} />
          <Stack>
            <Stack gap={4}>
              <Stack onClick={openChat}>
                <HStack
                  cursor={'pointer'}
                  bgColor={'rgba(247, 249, 251, 1)'}
                  borderRadius={'10px'}
                  px={4}
                  py={2}
                >
                  <BsPlusCircleFill size={20} />
                  <Text color={'#1C1C1C'} fontSize={'sm'} fontWeight={'400'}>
                    New Chat
                  </Text>
                </HStack>
              </Stack>
              {/* view/hide toggler */}
              <HStack mx={3} spacing={3}>
                <Text fontSize={'lg'} color={'#418DB8'} _hover={{}}>
                  Chat History
                </Text>
                <Switch
                  isChecked={showHistory}
                  onChange={() => setshowHistory(!showHistory)}
                  size={'md'}
                  id="isChecked"
                />
              </HStack>
              {/* chat history */}
              {showHistory && (
                <Stack>
                <Stack maxH={'50vh'} overflowY={'auto'}>
                  <Stack
                    bgColor={'rgba(247, 249, 251, 1)'}
                    borderRadius={'10px'}
                    p={4}
                    // mx={3}
                  >
                    {sessions && sessions.length == 0 ? (
                      <Text textAlign={'center'}>No sessions yet</Text>
                    ) : (
                      sessions?.map(session => (
                        <Stack>
                          <Stack
                            onClick={() => {
                              navigator(`/chat/${session?._id}`);
                            }}
                            cursor={'pointer'}
                            alignItems={'center'}
                            direction={'row'}
                            justifyContent={'space-between'}
                          >
                            <HStack spacing={2} py={1}>
                              <Img src={icon1} />
                              <Text
                                color={'rgba(28, 28, 28, 1)'}
                                fontSize={'14px'}
                                fontWeight={'400'}
                              >
                               
                                {session?.title?.slice(0, 20)}
                              </Text>
                            </HStack>
                            <RiDeleteBin5Line
                              onClick={event => {
                                event.stopPropagation();
                                deleteSession(session?._id).then(res => {
                                  openChat();
                                  navigator('/chat');
                                  // }
                                });
                              }}
                              fontSize={'17px'}
                            />
                          </Stack>
                          <Divider />
                        </Stack>
                      ))
                    )}
                  </Stack>
                </Stack>
                <Button
                color={'white'}
                fontSize={'13px'}
                fontWeight={'600'}
                bgGradient="linear-gradient(15deg, #17496D 0%, #418DB8 92.71%)"
                _hover={{}}
                onClick={() => {
                  deleteAllSession().then(res => {
                    openChat();
                    navigator('/chat');
                  });
                }}
              >
                Clear All Conversations
              </Button>
              </Stack>
                
              )}
           
            </Stack>
          </Stack>
        </Stack>

        <Stack
          bgColor={'rgba(255, 255, 255, 1)'}
          boxShadow={'2xl'}
          borderRadius={'20px'}
          direction={'row'}
          mx={3}
          justifyContent={'space-between'}
          mt={4}
        >
          <HStack marginLeft={4}>
            {/**/}
            <NameAvatar name={userInfo?.name} />
            <Text color={'#1B2559'} fontSize={'14px'} fontWeight={'700'}>
              {userInfo?.name}
            </Text>
          </HStack>
          <Stack>
            <Button
              onClick={() => {
                localStorage.clear();
                navigator('/');
              }}
              colorScheme="teal"
              variant="none"
            >
              <Img src={signout} />
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Sidebar;
