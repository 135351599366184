import { Button, HStack, Heading, Img, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import introImage from '../assets/images/introImage.png';
import logo from '../assets/images/logo2.svg';
import { useNavigate } from 'react-router-dom';
const LandingPage = () => {
  const nav = useNavigate();
  return (
    <Stack h="100vh" p={{base:0,md:2,xl:6}}>
      <Stack direction={'row'}>
        <Stack minH={'92vh'} justifyContent={'space-between'} py={4} px={{base:4,sm:4,md:8,xl:28}} w={{base:'100%',lg:'55%'}} alignItems={'center'}>
          <Img w={'150px'} src={logo} />
          <Stack mt={{base:4}}   >
            <Text fontWeight={'700'} fontSize={{base:'2xl',md:'3xl',xl:'4xl'}}>
              Introducing A2AL
            </Text>
            <Text color="#8F90A6" fontSize={{base:'sm',md:'md'}}>
              Introducing A2AL, your legal companion for navigating the
              intricacies of Ontario law with confidence. A2AL stands for
              'Answers to Ontario Law,' and it's more than just an application –
              it's your go-to resource for accurate and insightful responses to
              legal queries specific to the Ontario jurisdiction. Whether you're
              an individual seeking clarity on legal matters or a business
              ensuring compliance, A2AL is here to provide reliable answers.
              Join us on a journey where legal guidance is at your fingertips,
              empowering you to make informed decisions in alignment with
              Ontario law. Welcome to A2AL, where legal clarity meets
              convenience
            </Text>
            <Stack mt={6} w="100%" spacing={4}>
              <Button
                onClick={() => nav('/login')}
                fontWeight="500"
                color="white"
                borderRadius="full"
                bg="var(--gradient, linear-gradient(15deg, #17496D 0%, #418DB8 92.71%))"
                _hover={{}}
                w="100%"
              >
                Login
              </Button>
              <Button
                border="1px solid #17496D"
                onClick={() => nav('/signup')}
                fontWeight="600"
                color="#17496D"
                borderRadius="full"
                bg="transparent"
                _hover={{}}
                w="100%"
              >
                Signup
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Stack display={{base:'none',lg:'inherit'}} w="45%">
          <Img h="92vh" src={introImage} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LandingPage;
